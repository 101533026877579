import Vue from 'vue';

import App from './App.vue';
import i18n from './locale';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

import BaseBoxCard from '@/components/BaseBoxCard.vue';
import BaseErrorMessage from '@/components/BaseErrorMessage.vue';
import TheFramedWindow from '@/components/TheFramedWindow.vue';
import TheProblemAlert from '@/components/TheProblemAlert.vue';
import TheTxAssertModal from '@/components/TheTxAssertModal.vue';
import TheTxMessageSelector from '@/components/TheTxMessageSelector.vue';

Vue.component('base-box-card', BaseBoxCard);
Vue.component('base-error-message', BaseErrorMessage);
Vue.component('the-framed-window', TheFramedWindow);
Vue.component('the-problem-alert', TheProblemAlert);
Vue.component('the-tx-assert-modal', TheTxAssertModal);
Vue.component('the-tx-message-selector', TheTxMessageSelector);

Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
