import axios from 'axios';

import { APIS } from '@/constants';

const channelsApi = {
  /**
   *
   * @param {String} nextKey
   * @returns {AxiosPromise}
   */
  requestChannels({ nextKey }) {
    const params = {};
    if (nextKey) {
      params['pagination.key'] = nextKey;
    }
    return axios({
      url: `/channels`,
      method: 'get',
      baseURL: APIS.IBC_CORE_CHANNEL,
      params,
    });
  },
};

export default channelsApi;
