<template>
  <v-row>
    <v-col cols="12" :md="md" :offset-md="offsetMd">
      <v-card flat outlined class="my-1 px-1 my-md-3 px-md-5">
        <v-toolbar flat>
          <v-spacer />
          <v-toolbar-title>
            <span class="font-weight-bold" v-text="title" />
          </v-toolbar-title>
          <v-spacer />
          <v-btn color="transparent" icon @click="$router.back()">
            <v-icon color="grey darken-3">{{ mdiCloseThick }}</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <slot name="main-content" />
        </v-card-text>
        <v-card-actions>
          <slot name="actions" />
        </v-card-actions>
        <v-card-text
          v-if="footerMessage"
          class="text-caption text-center font-weight-bold"
        >
          <span v-html="footerMessage" />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mdiCloseThick } from '@mdi/js';

export default {
  name: 'TheFramedWindow',
  props: {
    md: {
      type: Number,
      default: 8,
      note: 'The window cols',
    },
    title: {
      type: String,
      required: true,
      note: 'The window title',
    },
    footerMessage: {
      type: String,
      default: '',
      note: 'The footer content',
    },
  },
  data() {
    return {
      mdiCloseThick,
    };
  },
  computed: {
    offsetMd() {
      if (this.md % 2 !== 0) {
        return 0;
      }
      return (12 - this.md) / 2;
    },
  },
};
</script>
