const titles = {
  accounts: 'Accounts',
  commercioNetwork: 'Commercio.network',
  createGaugeTx: 'Create gauge tx',
  dexPrice: 'DEX Price',
  founder: 'Founder',
  history: 'History',
  inCirculation: 'In Circulation',
  info: 'Info',
  manageAdjustments: 'Manage Adjustments',
  manageInvestments: 'Manage Investments',
  manageLiquidity: 'Manage liquidity',
  manageSettings: 'Manage Settings',
  mintTokens: 'Mint Tokens',
  moreInfo: 'More info',
  operations: 'Operations',
  osmosis: 'Osmosis',
  poolManagement: 'Pool Management',
  presalePrice: 'Presale Price',
  referencePrice: 'Reference Price',
  reservePrice: 'Reserve Price',
  rivCoin: 'RIVCoin',
  settings: 'Settings',
  stats: 'Stats',
  treasury: 'Treasury',
  users: 'Users',
};

export default titles;
