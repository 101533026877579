import { initState } from './index';

export default {
  reset(state) {
    Object.assign(state, initState());
  },
  setError(state, error) {
    state.error = error;
  },
  setExecuting(state, payload) {
    state.isExecuting = payload;
  },
  setLoading(state, payload) {
    state.isLoading = payload;
  },
  setBalances(state, payload) {
    state.balances = payload;
  },
  addBalance(state, payload) {
    Object.assign(state.balances, payload);
  },
};
