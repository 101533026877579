import { OSMOSIS } from '@/constants';

export default {
  error: (state) => state.error,
  isExecuting: (state) => state.isExecuting,
  isLoading: (state) => state.isLoading,
  locks: (state) => state.locks,
  unbondableLocks: (state) =>
    state.locks.filter(
      (lock) => lock.end_time === OSMOSIS.LOCK_FILTER.END_TIME,
    ),
};
