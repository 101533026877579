import { ROUTES } from '@/constants';
import { loadView } from '../index';

const ManageLiquidityView = loadView('manage-liquidity/ManageLiquidityView');

const manageLiquidityRoute = {
  path: ROUTES.PATH.MANAGE_LIQUIDITY,
  name: ROUTES.NAME.MANAGE_LIQUIDITY,
  component: ManageLiquidityView,
  meta: {
    title: ROUTES.TITLE.MANAGE_LIQUIDITY,
  },
};

export default manageLiquidityRoute;
