const BECH32_PREFIX = 'did:com:';

const PREFIXES = Object.freeze({
  ACCOUNT: {
    ADDRESS: `${BECH32_PREFIX}`,
    KEY: `${BECH32_PREFIX}pub`,
  },
  VALIDATOR: {
    CONSENSUS: {
      ADDRESS: `${BECH32_PREFIX}valcons`,
      KEY: `${BECH32_PREFIX}valconspub`,
    },
    OPERATOR: {
      ADDRESS: `${BECH32_PREFIX}valoper`,
      KEY: `${BECH32_PREFIX}valoperpub`,
    },
  },
});

const STABLE_COIN = Object.freeze({
  NAME: 'Commercio Cash Credits',
  SYMBOL: 'CCC',
  DENOM: 'uccc',
  EXPONENT: 6,
  VALUE: {
    AMOUNT: '1.00',
    SYMBOL: '€',
  },
});

const TOKEN = Object.freeze({
  NAME: 'Commercio',
  SYMBOL: 'COM',
  DENOM: 'ucommercio',
  EXPONENT: 6,
  STAKEABLE: true,
});

const GAS = Object.freeze({
  LIMIT: 1000000,
  PRICE: {
    AVERAGE: 0.125,
    HIGH: 0.15,
    LOW: 0.1,
  },
});

const INFO = Object.freeze({
  rpc: process.env.VUE_APP_CHAIN_RPC,
  rest: process.env.VUE_APP_CHAIN_LCD,
  chainId: process.env.VUE_APP_CHAIN_ID,
  chainName: process.env.VUE_APP_CHAIN_NAME,
  stakeCurrency: {
    coinDenom: TOKEN.SYMBOL,
    coinMinimalDenom: TOKEN.DENOM,
    coinDecimals: TOKEN.EXPONENT,
  },
  bip44: {
    coinType: 118,
  },
  bech32Config: {
    bech32PrefixAccAddr: PREFIXES.ACCOUNT.ADDRESS,
    bech32PrefixAccPub: PREFIXES.ACCOUNT.KEY,
    bech32PrefixValAddr: PREFIXES.VALIDATOR.OPERATOR.ADDRESS,
    bech32PrefixValPub: PREFIXES.VALIDATOR.OPERATOR.KEY,
    bech32PrefixConsAddr: PREFIXES.VALIDATOR.CONSENSUS.ADDRESS,
    bech32PrefixConsPub: PREFIXES.VALIDATOR.CONSENSUS.KEY,
  },
  currencies: [
    {
      coinDenom: TOKEN.SYMBOL,
      coinMinimalDenom: TOKEN.DENOM,
      coinDecimals: TOKEN.EXPONENT,
    },
    {
      coinDenom: STABLE_COIN.SYMBOL,
      coinMinimalDenom: STABLE_COIN.DENOM,
      coinDecimals: STABLE_COIN.EXPONENT,
    },
  ],
  feeCurrencies: [
    {
      coinDenom: TOKEN.SYMBOL,
      coinMinimalDenom: TOKEN.DENOM,
      coinDecimals: TOKEN.EXPONENT,
      gasPriceStep: {
        low: GAS.PRICE.LOW,
        average: GAS.PRICE.AVERAGE,
        high: GAS.PRICE.HIGH,
      },
    },
    {
      coinDenom: STABLE_COIN.SYMBOL,
      coinMinimalDenom: STABLE_COIN.DENOM,
      coinDecimals: STABLE_COIN.EXPONENT,
      gasPriceStep: {
        low: GAS.PRICE.LOW,
        average: GAS.PRICE.AVERAGE,
        high: GAS.PRICE.HIGH,
      },
    },
  ],
});

export default Object.freeze({
  BECH32_PREFIX,
  GAS,
  INFO,
  PREFIXES,
  STABLE_COIN,
  TOKEN,
});
