import Vue from 'vue';
import Vuex from 'vuex';

import application from './application';
import home from './home';
import homeAccounts from './home/accounts';
import homeHistory from './home/history';
import homeRivcoin from './home/rivcoin';
import homeWallets from './home/wallets';
import keplr from './keplr';
import manageAdjustments from './manage-adjustments';
import manageInvestments from './manage-investments';
import manageLiquidity from './manage-liquidity';
import mintTokens from './mint-tokens';
import poolManagement from './pool-management';
import poolManagementAddToGauge from './pool-management/add-to-gauge';
import poolManagementBondPoolToken from './pool-management/bond-pool-token';
import poolManagementCreateGauge from './pool-management/create-gauge';
import poolManagementUnbondPoolToken from './pool-management/unbond-pool-token';
import settingsManager from './settings/manager';
import settingsSummary from './settings/summary';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    // root
    application,
    // namespaced
    home,
    homeAccounts,
    homeHistory,
    homeRivcoin,
    homeWallets,
    keplr,
    manageAdjustments,
    manageInvestments,
    manageLiquidity,
    mintTokens,
    poolManagement,
    poolManagementAddToGauge,
    poolManagementBondPoolToken,
    poolManagementCreateGauge,
    poolManagementUnbondPoolToken,
    settingsManager,
    settingsSummary,
  },
});
