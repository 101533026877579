import Vue from 'vue';
import VueI18n from 'vue-i18n';

import { LOCALE } from '@/constants';
import en from './en/index.js';

Vue.use(VueI18n);

const defaultLocale = LOCALE.find((locale) => locale.default);
const availableLocales = LOCALE.map((locale) => locale.value);

const i18n = new VueI18n({
  locale: defaultLocale.value,
  fallbackLocale: defaultLocale.value,
  messages: { en },
  availableLocales: availableLocales,
  silentFallbackWarn: true,
});

export default i18n;
