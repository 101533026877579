import axios from 'axios';

import { APIS } from '@/constants';

const osmosisLockupApi = {
  requestAccountLockedPasttime(wallet) {
    return axios({
      url: `/account_locked_pasttime/${wallet}`,
      method: 'get',
      baseURL: APIS.OSMOSIS_LOCKUP,
    });
  },
};

export default osmosisLockupApi;
