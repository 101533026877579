import { ACCOUNTS, WALLETS } from '@/constants';

export default {
  error: (state) => state.error,
  isLoading: (state) => state.isLoading,
  contract: (state) => state.contract,
  founder: (state) => state.founder,
  spotPrice: (state) => state.spotPrice,
  info: (state) => state.contract?.info,
  genesisAmount: (state) => {
    if (!state.contract.wallets) {
      return 0;
    }
    const wallet = state.contract.wallets.wallets_balance.find(
      (w) => w.id === WALLETS.ID.GENESIS,
    );
    return parseInt(wallet.amount) || 0;
  },
  vault: (state) => {
    if (!state.contract.accounts) {
      return 0;
    }
    return state.contract.accounts.accounts_balance.reduce((acc, account) => {
      if (
        account.id === ACCOUNTS.ID.CASH_EQUIVALENTS ||
        account.id === ACCOUNTS.ID.SALE
      ) {
        return acc + parseInt(account.amount);
      }
      return acc;
    }, 0);
  },
};
