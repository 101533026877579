import { osmosis } from 'osmojs';
import { stringHandler } from '@/utils';
import { OSMOSIS } from '@/constants';

export default {
  async initPoolManagement({ commit, dispatch }) {
    commit('reset');
    commit('setLoading', true);
    const requests = [dispatch('fetchAllBalances')];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  async refreshPoolManagement({ commit, dispatch }) {
    commit('setBalances', []);
    const requests = [dispatch('fetchAllBalances')];
    await Promise.all(requests);
  },
  async fetchAllBalances({ commit, rootGetters }) {
    const { createRPCQueryClient } = osmosis.ClientFactory;
    const address = rootGetters['keplr/wallet'];
    const chainInfo = OSMOSIS.INFO;
    let pagination;
    let nextKey = '';
    try {
      const client = await createRPCQueryClient({
        rpcEndpoint: chainInfo.rpc,
      });
      do {
        if (nextKey) {
          pagination['key'] = nextKey;
        }
        const response = await client.cosmos.bank.v1beta1.allBalances({
          address,
          pagination,
        });
        commit('addBalances', response.balances);
        nextKey = stringHandler.unit8ArrayToString(response.pagination.nextKey);
      } while (nextKey);
    } catch (error) {
      commit('setError', error);
    }
  },
};
