import { ROUTES } from '@/constants';
import { loadView } from '../index';

const MintTokensView = loadView('mint-tokens/MintTokensView');

const mintTokensRoute = {
  path: ROUTES.PATH.MINT_TOKENS,
  name: ROUTES.NAME.MINT_TOKENS,
  component: MintTokensView,
  meta: {
    title: ROUTES.TITLE.MINT_TOKENS,
  },
};

export default mintTokensRoute;
