import { ROUTES } from '@/constants';
import { loadView } from '../index';

const NotFoundView = loadView('NotFoundView');

const notFoundRoute = {
  path: ROUTES.PATH.NOT_FOUND,
  name: ROUTES.NAME.NOT_FOUND,
  component: NotFoundView,
  meta: {
    title: ROUTES.TITLE.NOT_FOUND,
  },
};

export default notFoundRoute;
