const STATE_KEY = Object.freeze({
  CONFIG: 'config',
  CONTRACT_INFO: 'contract_info',
  DAO_ICS: 'dao_ics',
  DAO_TOKEN: 'dao_token',
});

export default Object({
  STATE_KEY,
});
