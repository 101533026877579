import { initState } from './index';
import historyAdapter from './history-adapter';

export default {
  reset(state) {
    Object.assign(state, initState());
  },
  setError(state, error) {
    state.error = error;
  },
  setLoading(state, payload) {
    state.isLoading = payload;
  },
  setIncreasing(state, payload) {
    state.isIncreasing = payload;
  },
  saveOsmosisReceived(state, payload) {
    const items = historyAdapter.getItems(payload.tx_responses);
    const list = [...state.osmosis_received.list, ...items];
    state.osmosis_received.list = list;
    state.osmosis_received.offset += items.length;
    state.osmosis_received.pagination = payload.pagination;
  },
  saveOsmosisSent(state, payload) {
    const items = historyAdapter.getItems(payload.tx_responses);
    const list = [...state.osmosis_sent.list, ...items];
    state.osmosis_sent.list = list;
    state.osmosis_sent.offset += items.length;
    state.osmosis_sent.pagination = payload.pagination;
  },
  saveRiv(state, payload) {
    const items = historyAdapter.getItems(payload.tx_responses);
    const list = [...state.riv.list, ...items];
    state.riv.list = list;
    state.riv.offset += items.length;
    state.riv.pagination = payload.pagination;
  },
};
