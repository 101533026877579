import axios from 'axios';

import { APIS } from '@/constants';

const osmosisGammApi = {
  requestPool(poolId) {
    return axios({
      url: `/pools/${poolId}`,
      method: 'get',
      baseURL: APIS.OSMOSIS_GAMM_V1,
    });
  },
  requestDexPrice({ poolId, denom1, denom2 }) {
    return axios({
      url: `/pools/${poolId}/prices?base_asset_denom=${denom1}&quote_asset_denom=${denom2}`,
      method: 'get',
      baseURL: APIS.OSMOSIS_GAMM_V2,
    });
  },
};

export default osmosisGammApi;
