import { ACCOUNTS } from '@/constants';

export default {
  error: (state) => state.error,
  isLoading: (state) => state.isLoading,
  accounts: (state) => {
    const accounts = [];
    let vault = 0;
    for (const account of state.accounts) {
      if (
        account.id === ACCOUNTS.ID.CASH_EQUIVALENTS ||
        account.id === ACCOUNTS.ID.SALE
      ) {
        const balance = parseInt(account.amount);
        accounts.push({
          id: account.id,
          rank: ACCOUNTS.RANK[account.id],
          label: ACCOUNTS.LABEL[account.id],
          balance,
        });
        vault += balance;
      }
    }
    accounts.push({
      id: ACCOUNTS.ID.VAULT,
      rank: ACCOUNTS.RANK[ACCOUNTS.ID.VAULT],
      label: ACCOUNTS.LABEL[ACCOUNTS.ID.VAULT],
      balance: vault,
    });
    return accounts.sort((a, b) => a.rank - b.rank);
  },
};
