import { cosmwasmApi } from '@/apis';
import { CONFIG, CONTRACT } from '@/constants';
import { stringHandler } from '@/utils';

export default {
  async initSettingsSummary({ commit, dispatch }) {
    commit('reset');
    commit('setLoading', true);
    const requests = [dispatch('fetchFounder')];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  async fetchFounder({ commit, dispatch }) {
    try {
      const response = await cosmwasmApi.requestContractState(
        CONFIG.CONTRACT.DAO,
      );
      const models = response.data.models;
      for (const model of models) {
        const key = stringHandler.decodeFromHex(model.key);
        if (key === CONTRACT.STATE_KEY.CONFIG) {
          const detail = JSON.parse(
            stringHandler.decodeFromBase64(model.value),
          );
          commit('setDetail', detail);
          if (detail.founder_address) {
            await dispatch('getBalance', detail.founder_address);
          }
        }
      }
    } catch (error) {
      commit('setError', error);
    }
  },
  async getBalance({ commit }, address) {
    const query = stringHandler.encodeToBase64(
      `{"balance":{"address":"${address}"}}`,
    );
    try {
      const response = await cosmwasmApi.requestContractSmartQuery({
        address: CONFIG.CONTRACT.CW20,
        query_data: query,
      });
      commit('addDetail', response.data.data);
    } catch (error) {
      commit('setError', error);
    }
  },
};
