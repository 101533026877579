import { osmosisTxApi, txApi } from '@/apis';
import { CONFIG, OSMOSIS } from '@/constants';

const EVENT_OSMOSIS_RECIPIENT = `transfer.recipient='${OSMOSIS.WALLET}'`;
const EVENT_OSMOSIS_SENDER = `transfer.sender='${OSMOSIS.WALLET}'`;
const EVENT_RIV_SENDER = `transfer.sender='${CONFIG.WALLET}'`;

export default {
  async initHomeHistory({ commit, dispatch }) {
    commit('reset');
    commit('setLoading', true);
    const requests = [
      // dispatch('getOsmosisReceived'),
      dispatch('getOsmosisSent'),
      dispatch('getRiv'),
    ];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  async increaseOsmosisHistory({ commit, dispatch, getters }) {
    const requests = [];
    const osmosisReceived = getters['osmosisReceived'];
    if (osmosisReceived.hasMore) {
      requests.push(dispatch('getOsmosisReceived', osmosisReceived.offset));
    }
    const osmosisSent = getters['osmosisSent'];
    if (osmosisSent.hasMore) {
      requests.push(dispatch('getOsmosisSent', osmosisSent.offset));
    }
    commit('setIncreasing', true);
    await Promise.all(requests);
    commit('setIncreasing', false);
  },
  async increaseRivHistory({ commit, dispatch }, offset) {
    commit('setIncreasing', true);
    await dispatch('getRiv', offset);
    commit('setIncreasing', false);
  },
  async getOsmosisReceived({ commit, dispatch }, offset) {
    const events = [EVENT_OSMOSIS_RECIPIENT];
    const response = await dispatch('fetchOsmosisHistory', { events, offset });
    if (response) {
      commit('saveOsmosisReceived', response);
    }
  },
  async getOsmosisSent({ commit, dispatch }, offset) {
    const events = [EVENT_OSMOSIS_SENDER];
    const response = await dispatch('fetchOsmosisHistory', { events, offset });
    if (response) {
      commit('saveOsmosisSent', response);
    }
  },
  async getRiv({ commit, dispatch }, offset) {
    const events = [EVENT_RIV_SENDER];
    const response = await dispatch('fetchRivHistory', { events, offset });
    if (response) {
      commit('saveRiv', response);
    }
  },
  async fetchOsmosisHistory(
    { commit },
    {
      events = [],
      offset = 0,
      limit = CONFIG.LIMIT.HISTORY,
      countTotal = true,
      orderBy = CONFIG.SORTING_ORDERS.ORDER_BY_DESC,
    } = {},
  ) {
    try {
      const response = await osmosisTxApi.requestTxs({
        events,
        offset,
        limit,
        countTotal,
        orderBy,
      });
      return response.data;
    } catch (error) {
      commit('setError', error);
    }
  },
  async fetchRivHistory(
    { commit },
    {
      events = [],
      offset = 0,
      limit = CONFIG.LIMIT.HISTORY,
      countTotal = true,
      orderBy = CONFIG.SORTING_ORDERS.ORDER_BY_DESC,
    } = {},
  ) {
    try {
      const response = await txApi.requestTxs({
        events,
        offset,
        limit,
        countTotal,
        orderBy,
      });
      return response.data;
    } catch (error) {
      commit('setError', error);
    }
  },
};
