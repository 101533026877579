import { TRANSACTIONS } from '@/constants';

const historyAdapter = {
  getItems(history) {
    return history.map((it) => {
      const msgs = it.tx.body.messages;
      const hash = it.txhash;
      const timestamp = it.timestamp;
      const wallet = getWallet(it);
      const key = getKey(msgs);
      const amount = getAmount(msgs, key);
      const type = getType(msgs, key);
      return {
        amount,
        hash,
        key,
        timestamp,
        type,
        wallet,
      };
    });
  },
};

export default historyAdapter;

const getKey = (msgs) => {
  if (msgs && msgs.length > 1) {
    return TRANSACTIONS.MSG.KEY.MULTI_TYPE;
  }
  return msgs[0]['msg']
    ? Object.keys(msgs[0]['msg'])[0]
    : msgs[0]['@type']
    ? msgs[0]['@type']
    : '';
};

const getAmount = (msgs, key) => {
  if (!key) {
    return '-';
  }
  return msgs[0]['msg'] && msgs[0]['msg'][key] && msgs[0]['msg'][key]['amount']
    ? msgs[0]['msg'][key]['amount']
    : '-';
};

const getType = (msgs, key) => {
  switch (key) {
    case TRANSACTIONS.MSG.KEY.ACCOUNT_ADJUSTMENT:
      return TRANSACTIONS.MSG_TEXT[
        msgs[0]['msg'][key]['type_tx'] + TRANSACTIONS.HTML_SUFFIX
      ];
    case TRANSACTIONS.MSG.KEY.ACCOUNT_TRANSACTION:
    case TRANSACTIONS.MSG.KEY.WALLET_TRANSACTION:
      return TRANSACTIONS.MSG_TEXT[msgs[0]['msg'][key]['type_tx']];
    case TRANSACTIONS.MSG.KEY.ADD_LIQUIDITY:
      return TRANSACTIONS.MSG_TEXT[TRANSACTIONS.MSG.TYPE.ADD_LIQUIDITY];
    case TRANSACTIONS.MSG.KEY.ADD_TO_GAUGE:
      return TRANSACTIONS.MSG_TEXT[TRANSACTIONS.MSG.TYPE.ADD_TO_GAUGE];
    case TRANSACTIONS.MSG.KEY.BOND_POOL_TOKEN:
      return TRANSACTIONS.MSG_TEXT[TRANSACTIONS.MSG.TYPE.BOND_POOL_TOKEN];
    case TRANSACTIONS.MSG.KEY.CREATE_GAUGE:
      return TRANSACTIONS.MSG_TEXT[TRANSACTIONS.MSG.TYPE.CREATE_GAUGE];
    case TRANSACTIONS.MSG.KEY.CREATE_POOL:
      return TRANSACTIONS.MSG_TEXT[TRANSACTIONS.MSG.TYPE.CREATE_POOL];
    case TRANSACTIONS.MSG.KEY.FOUNDER_CONFIG:
      return TRANSACTIONS.MSG_TEXT[TRANSACTIONS.MSG.TYPE.FOUNDER_CONFIG];
    case TRANSACTIONS.MSG.KEY.MULTI_TYPE:
      return TRANSACTIONS.MSG_TEXT[TRANSACTIONS.MSG.TYPE.MULTI_TYPE];
    case TRANSACTIONS.MSG.KEY.REFERENCE_PRICE_CONFIG:
      return TRANSACTIONS.MSG_TEXT[
        TRANSACTIONS.MSG.TYPE.REFERENCE_PRICE_CONFIG
      ];
    case TRANSACTIONS.MSG.KEY.REMOVE_LIQUIDITY:
      return TRANSACTIONS.MSG_TEXT[TRANSACTIONS.MSG.TYPE.REMOVE_LIQUIDITY];
    case TRANSACTIONS.MSG.KEY.UNBOND_POOL_TOKEN:
      return TRANSACTIONS.MSG_TEXT[TRANSACTIONS.MSG.TYPE.UNBOND_POOL_TOKEN];
    case TRANSACTIONS.MSG.KEY.WALLET_TO_BUYER_TRANSACTION:
      return TRANSACTIONS.MSG_TEXT[TRANSACTIONS.MSG.TYPE.SALE_OUT_TO_BUYER];
    case TRANSACTIONS.MSG.KEY.WALLET_TO_IBC_TRANSACTION:
      return TRANSACTIONS.MSG_TEXT[TRANSACTIONS.MSG.TYPE.GENESIS_TO_REWARDS];
    default:
      return '-';
  }
};

const getWallet = (tx) => {
  if (!tx.logs || !tx.logs.length || !tx.logs[0].events) {
    return '-';
  }
  const event = tx.logs[0].events.find((it) => it.type === 'message');
  if (!event || !event.attributes || !event.attributes.length) {
    return '-';
  }
  const sender = event.attributes.find((it) => it.key === 'sender');
  return sender && sender.value ? sender.value : '-';
};
