import { osmosisIncentivesApi } from '@/apis';
import { OSMOSIS } from '@/constants';
import { msgBuilder } from '@/utils';
import { FEES } from 'osmojs';

export default {
  async initAddToGauge({ commit, dispatch }) {
    commit('reset');
    commit('setLoading', true);
    const requests = [dispatch('fetchGauges')];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  async refreshGauges({ commit, dispatch }) {
    commit('setGauges', []);
    await dispatch('fetchGauges');
  },
  async fetchGauges({ commit }) {
    // const denom = `gamm/pool/${OSMOSIS.POOL_ID}`;
    let nextKey;
    try {
      do {
        const response = await osmosisIncentivesApi.requestGauges({
          nextKey,
        });
        // const response = await osmosisIncentivesApi.requestActiveGaugesPerDenom(
        //   {
        //     denom,
        //     nextKey,
        //   },
        // );
        commit('addGauges', response.data.data);
        nextKey = response.data.pagination.next_key;
      } while (nextKey);
    } catch (error) {
      commit('setError', error);
    }
  },
  async addToGauge(
    { commit, dispatch, rootGetters },
    { gaugeId, reward, translator, context },
  ) {
    const owner = rootGetters['keplr/wallet'];
    const msg = msgBuilder.buildMsgAddToGauge({
      owner,
      gaugeId,
      rewards: [reward],
    });
    const chainInfo = OSMOSIS.INFO;
    const fee = FEES.osmosis.swapExactAmountIn('medium');
    commit('setExecuting', true);
    const client = await dispatch(
      'keplr/getOsmosisClient',
      { chainInfo },
      { root: true },
    );
    const res = await dispatch(
      'keplr/signAndBroadcastTransaction',
      { msgs: [msg], fee, client, translator, context },
      { root: true },
    );
    const requests = [
      dispatch('refreshGauges'),
      dispatch('poolManagement/refreshPoolManagement', null, {
        root: true,
      }),
    ];
    await Promise.all(requests);
    commit('setExecuting', false);
    return res;
  },
};
