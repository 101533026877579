import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const initState = () => ({
  error: null,
  isLoading: false,
  isIncreasing: false,
  osmosis_received: {
    list: [],
    offset: 0,
    pagination: null,
  },
  osmosis_sent: {
    list: [],
    offset: 0,
    pagination: null,
  },
  riv: {
    list: [],
    offset: 0,
    pagination: null,
  },
});

export default {
  namespaced: true,
  state: initState(),
  getters,
  mutations,
  actions,
};
