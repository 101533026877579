import { osmosisLockupApi } from '@/apis';
import { OSMOSIS } from '@/constants';
import { msgBuilder } from '@/utils';
import { FEES } from 'osmojs';

export default {
  async initUnbondPoolToken({ commit, dispatch }) {
    commit('reset');
    commit('setLoading', true);
    const requests = [dispatch('fetchAccountLockedPasttime')];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  async refreshAccountLockedPasttime({ commit, dispatch }) {
    commit('setLocks', []);
    await dispatch('fetchAccountLockedPasttime');
  },
  async fetchAccountLockedPasttime({ commit, rootGetters }) {
    const wallet = rootGetters['keplr/wallet'];
    try {
      const response = await osmosisLockupApi.requestAccountLockedPasttime(
        wallet,
      );
      commit('setLocks', response.data.locks);
    } catch (error) {
      commit('setError', error);
    }
  },
  async unbondPoolToken(
    { commit, dispatch, rootGetters },
    { amount, lockId, translator, context },
  ) {
    const owner = rootGetters['keplr/wallet'];
    const denom = `gamm/pool/${OSMOSIS.POOL_ID}`;
    const msg = msgBuilder.buildMsgBeginUnlocking({
      owner,
      ID: lockId,
      coins: [{ denom, amount }],
    });
    const chainInfo = OSMOSIS.INFO;
    const fee = FEES.osmosis.swapExactAmountIn('medium');
    commit('setExecuting', true);
    const client = await dispatch(
      'keplr/getOsmosisClient',
      { chainInfo },
      { root: true },
    );
    const res = await dispatch(
      'keplr/signAndBroadcastTransaction',
      { msgs: [msg], fee, client, translator, context },
      { root: true },
    );
    const requests = [
      dispatch('refreshAccountLockedPasttime'),
      dispatch('poolManagement/refreshPoolManagement', null, {
        root: true,
      }),
    ];
    await Promise.all(requests);
    commit('setExecuting', false);
    return res;
  },
};
