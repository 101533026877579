import { ROUTES } from '@/constants';
import { loadView } from '../index';

const SettingsRouter = loadView('settings/SettingsRouter');
const SettingsManagerView = loadView('settings/SettingsManagerView');
const SettingsSummaryView = loadView('settings/SettingsSummaryView');

const settingsRoute = {
  path: ROUTES.PATH.SETTINGS,
  component: SettingsRouter,
  meta: {
    title: ROUTES.TITLE.SETTINGS,
  },
  children: [
    {
      path: '',
      name: ROUTES.NAME.SETTINGS,
      component: SettingsSummaryView,
    },
    {
      path: ROUTES.PATH.SETTINGS_MANAGER,
      name: ROUTES.NAME.SETTINGS_MANAGER,
      component: SettingsManagerView,
      meta: {
        title: ROUTES.TITLE.SETTINGS_MANAGER,
      },
    },
    {
      path: ROUTES.PATH.SETTINGS_SUMMARY,
      name: ROUTES.NAME.SETTINGS_SUMMARY,
      component: SettingsSummaryView,
      meta: {
        title: ROUTES.TITLE.SETTINGS_SUMMARY,
      },
    },
  ],
};

export default settingsRoute;
