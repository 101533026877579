import { ROUTES } from '@/constants';
import { loadView } from '../index';

const ManageInvestmentsView = loadView(
  'manage-investments/ManageInvestmentsView',
);

const manageInvestmentsRoute = {
  path: ROUTES.PATH.MANAGE_INVESTMENTS,
  name: ROUTES.NAME.MANAGE_INVESTMENTS,
  component: ManageInvestmentsView,
  meta: {
    title: ROUTES.TITLE.MANAGE_INVESTMENTS,
  },
};

export default manageInvestmentsRoute;
