const MSG = Object.freeze({
  GROUP: {
    MANAGE_ADJUSTMENTS: 'manage_adjustments',
    MANAGE_ADJUSTMENTS_ACCOUNT_ADJUSTMENT:
      'manage_adjustments_account_adjustment',
    MANAGE_INVESTMENTS: 'manage_investments',
    MANAGE_LIQUIDITY: 'manage_liquidity',
    MINT_TOKENS: 'mint_tokens',
    POOL_MANAGEMENT: 'pool_management',
    SETTINGS: 'settings',
  },
  KEY: {
    ACCOUNT_ADJUSTMENT: 'account_adjustment',
    ACCOUNT_TRANSACTION: 'account_transaction',
    ADD_LIQUIDITY: '/osmosis.gamm.v1beta1.MsgJoinPool',
    ADD_TO_GAUGE: '/osmosis.incentives.MsgAddToGauge',
    BOND_POOL_TOKEN: '/osmosis.lockup.MsgLockTokens',
    COSMOS_tx: '/cosmos.tx.v1beta1.Tx',
    CREATE_GAUGE: '/osmosis.incentives.MsgCreateGauge',
    CREATE_POOL:
      '/osmosis.gamm.poolmodels.balancer.v1beta1.MsgCreateBalancerPool',
    FOUNDER_CONFIG: 'founder_config',
    MULTI_TYPE: 'multi_type',
    REFERENCE_PRICE_CONFIG: 'reference_price_config',
    REMOVE_LIQUIDITY: '/osmosis.gamm.v1beta1.MsgExitPool',
    UNBOND_POOL_TOKEN: '/osmosis.lockup.MsgBeginUnlocking',
    WALLET_TO_BUYER_TRANSACTION: 'wallet_to_buyer_transaction',
    WALLET_TO_IBC_TRANSACTION: 'wallet_to_ibc_transaction',
    WALLET_TRANSACTION: 'wallet_transaction',
  },
  TYPE: {
    ACCOUNT_ADJUSTMENT: 'account_adjustiment',
    ACCOUNT_ADJUSTMENT_CASH_ACCOUNT_IN: 'cash_account_in',
    ACCOUNT_ADJUSTMENT_CASH_ACCOUNT_OUT: 'cash_account_out',
    ACCOUNT_ADJUSTMENT_CASH_EQUIVALENT_IN: 'cash_equivalent_in',
    ACCOUNT_ADJUSTMENT_CASH_EQUIVALENT_OUT: 'cash_equivalent_out',
    ADD_LIQUIDITY: 'ADD_LIQUIDITY',
    ADD_TO_GAUGE: 'ADD_TO_GAUGE',
    BOND_POOL_TOKEN: 'BOND_POOL_TOKEN',
    CREATE_GAUGE: 'CREATE_GAUGE',
    CREATE_POOL: 'CREATE_POOL',
    DECREASE_NAV: 'DECREASE_NAV',
    FOUNDER_CONFIG: 'FOUNDER_CONFIG',
    GENESIS_TO_REWARDS: 'GENESIS_TO_REWARDS',
    INCREASE_NAV: 'INCREASE_NAV',
    IN_INVESTMENT: 'IN_INVESTMENT',
    IN_SALE: 'IN_SALE',
    IN_SALE_TO_REWARDS_USDC: 'IN_SALE_TO_REWARDS_USDC',
    GENESIS_TO_MINING: 'GENESIS_TO_MINING',
    MULTI_TYPE: 'MULTI_TYPE',
    OUT_INVESTMENT: 'OUT_INVESTMENT',
    REFERENCE_PRICE_CONFIG: 'REFERENCE_PRICE_CONFIG',
    REMOVE_LIQUIDITY: 'REMOVE_LIQUIDITY',
    RIVTECH_CREDIT_LP: 'RIVTECH_CREDIT_LP',
    RIVTECH_CREDIT_VAULT: 'RIVTECH_CREDIT_VAULT',
    UNBOND_POOL_TOKEN: 'UNBOND_POOL_TOKEN',
    SALE_OUT_TO_BUYER: 'SALE_OUT_TO_BUYER',
  },
});

const MSG_DESCRIPTION = Object.freeze({
  [MSG.TYPE.ACCOUNT_ADJUSTMENT]:
    'For adjustment of Sale and Cash Equivalents not due to NAV changes',
  [MSG.TYPE.ACCOUNT_ADJUSTMENT_CASH_ACCOUNT_IN]:
    'For the inflow in the Sale’s Bank Account not due to an new purchase of RIV coins (e.g. reimbursement)',
  [MSG.TYPE.ACCOUNT_ADJUSTMENT_CASH_ACCOUNT_OUT]:
    'For the outflow in the Sale’s Bank Account not originated by investments (e.g. bank commissions)',
  [MSG.TYPE.ACCOUNT_ADJUSTMENT_CASH_EQUIVALENT_IN]:
    'For the inflow on the Cash Equivalents not derived from Sale',
  [MSG.TYPE.ACCOUNT_ADJUSTMENT_CASH_EQUIVALENT_OUT]:
    'For the outflow on the Cash Equivalents not derived from disinvestments',
  [MSG.TYPE.ADD_TO_GAUGE]: 'Adds coins to a previously created gauge',
  [MSG.TYPE.BOND_POOL_TOKEN]: 'Bond pool token',
  [MSG.TYPE.CREATE_GAUGE]: 'Creates a new gauge to distribute rewards to users',
  [MSG.TYPE.DECREASE_NAV]: 'Decrease cash to the cash equivalents account',
  [MSG.TYPE.FOUNDER_CONFIG]: 'Manage the founder',
  [MSG.TYPE.GENESIS_TO_REWARDS]:
    'Move tokens from the genesis wallet to the rewards wallet',
  [MSG.TYPE.INCREASE_NAV]: 'Increase cash to the cash equivalents account',
  [MSG.TYPE.IN_INVESTMENT]:
    'Inflow to cash equivalents account and subtract them form the Sale account due to investments',
  [MSG.TYPE.IN_SALE]: 'Add cash to the sale account',
  [MSG.TYPE.IN_SALE_TO_REWARDS_USDC]:
    'Notarize the purchase of USDC for the LP',
  [MSG.TYPE.GENESIS_TO_MINING]:
    'Move tokens from the genesis wallet to the sale wallet',
  [MSG.TYPE.OUT_INVESTMENT]:
    'Outflow from cash equivalents account and add them to the Sale account due to disinvestments',
  [MSG.TYPE.REFERENCE_PRICE_CONFIG]: 'Manage the reference price',
  [MSG.TYPE.RIVTECH_CREDIT_LP]:
    'Notarize the initial credit for LP costitution',
  [MSG.TYPE.RIVTECH_CREDIT_VAULT]: "Notarize the initial shareholder's loan",
  [MSG.TYPE.UNBOND_POOL_TOKEN]: 'Unbond pool token',
  [MSG.TYPE.SALE_OUT_TO_BUYER]:
    'Transfer tokens form the Sale-out wallet to the wallet of a customer (Buyer)',
});

const MSG_SET = Object.freeze({
  [MSG.GROUP.MANAGE_ADJUSTMENTS]: [
    MSG.TYPE.ACCOUNT_ADJUSTMENT,
    MSG.TYPE.INCREASE_NAV,
    MSG.TYPE.DECREASE_NAV,
  ],
  [MSG.GROUP.MANAGE_ADJUSTMENTS_ACCOUNT_ADJUSTMENT]: [
    MSG.TYPE.ACCOUNT_ADJUSTMENT_CASH_ACCOUNT_IN,
    MSG.TYPE.ACCOUNT_ADJUSTMENT_CASH_ACCOUNT_OUT,
    MSG.TYPE.ACCOUNT_ADJUSTMENT_CASH_EQUIVALENT_IN,
    MSG.TYPE.ACCOUNT_ADJUSTMENT_CASH_EQUIVALENT_OUT,
  ],
  [MSG.GROUP.MANAGE_INVESTMENTS]: [
    MSG.TYPE.IN_INVESTMENT,
    MSG.TYPE.OUT_INVESTMENT,
    MSG.TYPE.GENESIS_TO_REWARDS,
  ],
  [MSG.GROUP.MINT_TOKENS]: [
    MSG.TYPE.IN_SALE,
    MSG.TYPE.IN_SALE_TO_REWARDS_USDC,
    MSG.TYPE.RIVTECH_CREDIT_VAULT,
    MSG.TYPE.RIVTECH_CREDIT_LP,
    MSG.TYPE.GENESIS_TO_MINING,
    MSG.TYPE.SALE_OUT_TO_BUYER,
  ],
  [MSG.GROUP.POOL_MANAGEMENT]: [
    MSG.TYPE.CREATE_GAUGE,
    MSG.TYPE.ADD_TO_GAUGE,
    MSG.TYPE.BOND_POOL_TOKEN,
    MSG.TYPE.UNBOND_POOL_TOKEN,
  ],
  [MSG.GROUP.SETTINGS]: [
    MSG.TYPE.FOUNDER_CONFIG,
    MSG.TYPE.REFERENCE_PRICE_CONFIG,
  ],
});

const HTML_SUFFIX = '-html';

const MSG_TEXT = Object.freeze({
  [MSG.TYPE.ACCOUNT_ADJUSTMENT]: 'Account adjustment',
  [MSG.TYPE.ACCOUNT_ADJUSTMENT_CASH_ACCOUNT_IN]: 'Cash account in',
  [MSG.TYPE.ACCOUNT_ADJUSTMENT_CASH_ACCOUNT_IN + HTML_SUFFIX]:
    'Account adjustment<br><span style="font-style:italic">(Cash account in)</span>',
  [MSG.TYPE.ACCOUNT_ADJUSTMENT_CASH_ACCOUNT_OUT]: 'Cash account out',
  [MSG.TYPE.ACCOUNT_ADJUSTMENT_CASH_ACCOUNT_OUT + HTML_SUFFIX]:
    'Account adjustment<br><span style="font-style:italic">(Cash account out)</span>',
  [MSG.TYPE.ACCOUNT_ADJUSTMENT_CASH_EQUIVALENT_IN]: 'Cash equivalent in',
  [MSG.TYPE.ACCOUNT_ADJUSTMENT_CASH_EQUIVALENT_IN + HTML_SUFFIX]:
    'Account adjustment<br><span style="font-style:italic">(Cash equivalent in)</span>',
  [MSG.TYPE.ACCOUNT_ADJUSTMENT_CASH_EQUIVALENT_OUT]: 'Cash equivalent out',
  [MSG.TYPE.ACCOUNT_ADJUSTMENT_CASH_EQUIVALENT_OUT + HTML_SUFFIX]:
    'Account adjustment<br><span style="font-style:italic">(Cash equivalent out)</span>',
  [MSG.TYPE.ADD_LIQUIDITY]: 'Add liquidity',
  [MSG.TYPE.ADD_TO_GAUGE]: 'Add to gauge',
  [MSG.TYPE.BOND_POOL_TOKEN]: 'Bond pool token',
  [MSG.TYPE.CREATE_GAUGE]: 'Create gauge',
  [MSG.TYPE.CREATE_POOL]: 'Create pool',
  [MSG.TYPE.DECREASE_NAV]: 'Decrease nav',
  [MSG.TYPE.FOUNDER_CONFIG]: 'Founder config',
  [MSG.TYPE.GENESIS_TO_REWARDS]: 'Genesis to rewards',
  [MSG.TYPE.INCREASE_NAV]: 'Increase nav',
  [MSG.TYPE.IN_INVESTMENT]: 'In investment',
  [MSG.TYPE.IN_SALE]: 'In sale',
  [MSG.TYPE.IN_SALE_TO_REWARDS_USDC]: 'In sale to rewards usdc',
  [MSG.TYPE.GENESIS_TO_MINING]: 'Genesis to mining',
  [MSG.TYPE.MULTI_TYPE]: 'Multi type',
  [MSG.TYPE.OUT_INVESTMENT]: 'Out investment',
  [MSG.TYPE.REFERENCE_PRICE_CONFIG]: 'Reference price config',
  [MSG.TYPE.REMOVE_LIQUIDITY]: 'Remove liquidity',
  [MSG.TYPE.RIVTECH_CREDIT_LP]: 'Rivtech credit LP',
  [MSG.TYPE.RIVTECH_CREDIT_VAULT]: 'Rivtech credit vault',
  [MSG.TYPE.UNBOND_POOL_TOKEN]: 'Unbond pool token',
  [MSG.TYPE.SALE_OUT_TO_BUYER]: 'Sale out to buyer',
});

const TYPE = Object.freeze({
  EXECUTE: '/cosmwasm.wasm.v1.MsgExecuteContract',
  INSTANTIATE: '/cosmwasm.wasm.v1.MsgInstantiateContract',
  STORE_CODE: '/cosmwasm.wasm.v1.MsgStoreCode',
});

const OSMOSIS_FILTER = Object.freeze([
  MSG.KEY.ADD_TO_GAUGE,
  MSG.KEY.ADD_LIQUIDITY,
  MSG.KEY.BOND_POOL_TOKEN,
  MSG.KEY.CREATE_GAUGE,
  MSG.KEY.CREATE_POOL,
  MSG.KEY.REMOVE_LIQUIDITY,
  MSG.KEY.UNBOND_POOL_TOKEN,
]);

export default Object.freeze({
  HTML_SUFFIX,
  MSG,
  MSG_DESCRIPTION,
  MSG_SET,
  MSG_TEXT,
  OSMOSIS_FILTER,
  TYPE,
});
