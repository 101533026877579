const gridHandler = {
  getColumnWidth: (array) => {
    const maxWidth = 12;
    const totalItems = array.length;
    if (!totalItems) {
      return maxWidth;
    }
    return totalItems < 5 ? maxWidth / totalItems : 2;
  },
};

export default gridHandler;
