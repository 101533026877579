const CONTRACT = Object.freeze({
  CW20: process.env.VUE_APP_CONTRACT_CW20,
  DAO: process.env.VUE_APP_CONTRACT_DAO,
  ICS20: process.env.VUE_APP_CONTRACT_ICS20,
});

const EXPLORER = Object.freeze({
  URL: {
    COMMERCIO: `${process.env.VUE_APP_EXPLORER_COMMERCIO}`,
    OSMOSIS: `${process.env.VUE_APP_EXPLORER_OSMOSIS}/osmosis`,
  },
  ROUTE: {
    COMMERCIO: {
      ACCOUNT: '/account',
      TX: '/transactions/detail',
    },
    OSMOSIS: {
      ACCOUNT: '/account',
      TX: '/tx',
    },
  },
});

const KEPLR = Object.freeze({
  EVENT: {
    KEYSTORECHANGE: 'keplr_keystorechange',
  },
});

const LIMIT = Object.freeze({
  HISTORY: 10,
});

const SORTING_ORDERS = Object.freeze({
  ORDER_BY_ASC: 'ORDER_BY_ASC',
  ORDER_BY_DESC: 'ORDER_BY_DESC',
  ORDER_BY_UNSPECIFIED: 'ORDER_BY_UNSPECIFIED',
});

const WALLET = process.env.VUE_APP_WALLET;

export default Object.freeze({
  CONTRACT,
  EXPLORER,
  KEPLR,
  LIMIT,
  SORTING_ORDERS,
  WALLET,
});
