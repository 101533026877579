import axios from 'axios';

import { APIS } from '@/constants';

const txApi = {
  requestTxs({ events, offset, limit, countTotal, orderBy }) {
    const params = {};
    if (events && events.length > 0) {
      params.events = events;
    }
    if (offset) {
      params['pagination.offset'] = offset;
    }
    if (limit) {
      params['pagination.limit'] = limit;
    }
    if (countTotal) {
      params['pagination.count_total'] = countTotal;
    }
    if (orderBy) {
      params['order_by'] = orderBy;
    }
    return axios({
      url: '/txs',
      method: 'get',
      baseURL: APIS.TX,
      params,
      // `paramsSerializer` is an optional config in charge of serializing `params`
      // Necessary because in this case the parameter 'events' can be added several times
      paramsSerializer: {
        indexes: null, // default false
      },
    });
  },
};

export default txApi;
