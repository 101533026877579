const tokensHandler = {
  convertFromBase(amount, decimals) {
    return parseInt(amount) / Math.pow(10, decimals);
  },
  convertToBase(amount, decimals) {
    return Math.round(parseFloat(amount) * Math.pow(10, decimals));
  },
  format(amount, decimals) {
    if (isNaN(parseFloat(amount)) || isNaN(parseFloat(decimals))) {
      return '';
    }
    return formatAmount(amount, decimals);
  },
  formatBalance({
    amount,
    decimals = 0,
    maximumFractionDigits = 0,
    minimumFractionDigits = 0,
  } = {}) {
    const balance = parseInt(amount) / Math.pow(10, decimals);
    return new Intl.NumberFormat(undefined, {
      style: 'decimal',
      maximumFractionDigits,
      minimumFractionDigits,
    }).format(balance);
  },
  /**
   *
   * @param {Object} balances
   * @param {String} balanceId
   * @param {String} balanceKey
   * @returns {Number}
   */
  getBalanceAmount({ items, balanceId, balanceKey }) {
    if (Object.keys(items).length === 0) {
      return 0;
    }
    const balances = items[balanceKey];
    const balance = balances.find((balance) => balance.id === balanceId);
    if (!balance) {
      return 0;
    }
    return parseInt(balance.amount);
  },
};

export default tokensHandler;

const formatAmount = (amount, decimals = 0) =>
  new Intl.NumberFormat(undefined, {
    style: 'decimal',
    maximumFractionDigits: decimals,
    minimumFractionDigits: decimals,
  }).format(amount);
