import { SigningCosmWasmClient } from '@cosmjs/cosmwasm-stargate';
import { assertIsDeliverTxSuccess } from '@cosmjs/stargate';
import { getSigningOsmosisClient } from 'osmojs';
import { CONFIG } from '@/constants';

let controller;

export default {
  async suggestChain({ commit, dispatch }, { chainInfo, translator, context }) {
    if (controller) {
      controller.abort();
    }
    commit('reset');
    commit('setLoading', true);
    const $t = translator.bind(context);
    if (!window.keplr) {
      commit('setHasKeplr', false);
      commit('setError', $t('msgs.installKeplrExtension'));
      return;
    }
    const chainId = chainInfo.chainId;
    if (!chainId) {
      commit('setError', $t('msgs.failedToDetectTheChain'));
      return;
    }
    try {
      await window.keplr.experimentalSuggestChain(chainInfo);
      await dispatch('getAccounts', { chainId, $t });
      dispatch('subscribeKeyStoreChange', { chainId, $t });
    } catch (error) {
      commit('setError', $t('msgs.failedToSuggestTheChain'));
    } finally {
      commit('setLoading', false);
    }
  },
  async getAccounts({ commit }, { chainId, $t }) {
    try {
      await window.keplr.enable(chainId);
      const offlineSigner = window.keplr.getOfflineSigner(chainId);
      const accounts = await offlineSigner.getAccounts();
      commit('setAccounts', accounts);
      commit('setConnected', true);
    } catch (error) {
      commit('setError', $t('msgs.noAccountFound'));
    }
  },
  async refreshAccounts({ commit, dispatch }, { chainId, $t }) {
    commit('setAccounts', []);
    commit('setConnected', false);
    commit('setLoading', true);
    await dispatch('getAccounts', { chainId, $t });
    commit('setLoading', false);
  },
  subscribeKeyStoreChange({ dispatch }, { chainId, $t }) {
    controller = new AbortController();
    window.addEventListener(
      CONFIG.KEPLR.EVENT.KEYSTORECHANGE,
      async () => await dispatch('refreshAccounts', { chainId, $t }),
      { signal: controller.signal },
    );
  },
  disconnect({ commit }) {
    commit('reset');
  },
  resetError({ commit }) {
    commit('setError', undefined);
  },
  async getOfflineSigner({ commit }, chainId) {
    try {
      return await window.keplr.getOfflineSignerAuto(chainId);
    } catch (error) {
      commit('setError', error);
    }
  },
  async getChainClient({ commit, dispatch }, { chainInfo }) {
    try {
      const signer = await dispatch('getOfflineSigner', chainInfo.chainId);
      const client = await SigningCosmWasmClient.connectWithSigner(
        chainInfo.rpc,
        signer,
      );
      return client;
    } catch (error) {
      commit('setError', error);
    }
  },
  async getOsmosisClient({ commit, dispatch }, { chainInfo }) {
    try {
      const signer = await dispatch('getOfflineSigner', chainInfo.chainId);
      const client = await getSigningOsmosisClient({
        rpcEndpoint: chainInfo.rpc,
        signer,
      });
      return client;
    } catch (error) {
      commit('setError', error);
    }
  },
  async signAndBroadcastTransaction(
    { commit, getters },
    { msgs, fee, client, translator, context },
  ) {
    try {
      const wallet = getters['wallet'];
      if (!wallet) {
        const $t = translator.bind(context);
        commit('setError', $t('msgs.noAccountFound'));
        return;
      }
      const result = await client.signAndBroadcast(wallet, msgs, fee);
      assertIsDeliverTxSuccess(result);
      return result;
    } catch (error) {
      commit('setError', error);
    }
    return null;
  },
};
