<template>
  <v-app :style="style">
    <v-container v-if="isLoading" fluid fill-height data-test="loading">
      <v-layout class="d-flex justify-center align-center">
        <v-progress-circular
          color="primary"
          indeterminate
          size="64"
          width="8"
        />
      </v-layout>
    </v-container>
    <v-container v-else-if="!isLoading && error" data-test="error">
      <base-error-message :error="error" />
    </v-container>
    <div v-else data-test="content">
      <the-app-bar />
      <v-main>
        <v-container>
          <router-view />
        </v-container>
      </v-main>
    </div>
  </v-app>
</template>

<script>
import '@/assets/style/app.scss';
import TheAppBar from '@/layout/TheAppBar.vue';

import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'App',
  components: { TheAppBar },
  computed: {
    ...mapGetters(['error', 'isLoading']),
    style() {
      return { background: this.$vuetify.theme.themes.light.background };
    },
  },
  created() {
    this.initApp();
  },
  methods: {
    ...mapActions(['initApp']),
  },
};
</script>
