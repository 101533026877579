<template>
  <v-select
    dense
    item-text="text"
    item-value="value"
    outlined
    single-line
    :items="items"
    :label="$t('labels.select')"
    v-model="select"
  >
    <template #append>
      <v-icon color="primary">{{ mdiChevronDown }}</v-icon>
    </template>
    <template #item="{ item }">
      <div class="text-caption">
        <span v-text="item.text" /> - <span v-text="item.description" />
      </div>
    </template>
  </v-select>
</template>

<script>
import { mdiChevronDown } from '@mdi/js';
import { TRANSACTIONS } from '@/constants';

export default {
  name: 'TheTxMessageSelector',
  props: {
    value: {
      type: String,
      required: true,
    },
    set: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      mdiChevronDown,
    };
  },
  computed: {
    select: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    items() {
      return this.set.map((it) => ({
        value: it,
        text: TRANSACTIONS.MSG_TEXT[it],
        description: TRANSACTIONS.MSG_DESCRIPTION[it],
      }));
    },
  },
};
</script>
