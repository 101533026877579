import Vue from 'vue';
import VueRouter from 'vue-router';

import { ROUTES } from '@/constants';
import homeRoute from './routes/home-route';
import manageAdjustmentsRoute from './routes/manage-adjustments-route';
import manageInvestmentsRoute from './routes/manage-investments-route';
import manageLiquidityRoute from './routes/manage-liquidity-route';
import mintTokensRoute from './routes/mint-tokens-route';
import notFoundRoute from './routes/not-found-route';
import poolManagementRoute from './routes/pool-management-route';
import settingsRoute from './routes/settings-route';

Vue.use(VueRouter);

const routes = [
  {
    path: ROUTES.PATH.ROOT,
    redirect: () => ROUTES.PATH.HOME,
  },
];

routes.push(
  homeRoute,
  manageAdjustmentsRoute,
  manageInvestmentsRoute,
  manageLiquidityRoute,
  mintTokensRoute,
  poolManagementRoute,
  settingsRoute,
  notFoundRoute,
);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;

export function loadView(view) {
  return () =>
    import(/* webpackChunkName: "view-[request]" */ `@/views/${view}.vue`);
}
