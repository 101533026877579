export default {
  async initHome({ commit, dispatch }) {
    commit('reset');
    commit('setLoading', true);
    const requests = [
      dispatch('homeAccounts/initHomeAccounts', null, { root: true }),
      dispatch('homeHistory/initHomeHistory', null, { root: true }),
      dispatch('homeRivcoin/initHomeRivcoin', null, { root: true }),
      dispatch('homeWallets/initHomeWallets', null, { root: true }),
    ];
    await Promise.all(requests);
    commit('setLoading', false);
  },
};
