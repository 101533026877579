const msgs = {
  assertAndViewTx:
    'The transaction was successful and can be viewed at this link',
  dataNotAvailable:
    'Sorry, at this point in time the data, unfortunately, is not available',
  failedToDetectTheChain: 'Failed to detect the chain',
  failedToSuggestTheChain: 'Failed to suggest the chain',
  feeBoundary: 'The fee rate must be a positive number less than 100',
  installKeplrExtension: 'Please install Keplr extension',
  noAccountFound:
    'No accounts found. Use the Keplr extension to create it or import it.',
  noCode: 'No code',
  noMessage: 'No message',
  notFound: '404 - Not found',
  noWalletConnected: 'No wallet connected',
  positiveAmount: 'Amount must be positive',
  problemOccurred: 'Sorry, there was a problem, please try again later',
  requiredAddress: 'The address is required',
  surplusAmount: 'The amount exceeds the available balance',
  triggeredAnError:
    'Something happened in setting up the request that triggered an error',
  unansweredRequest: 'The request was made but no response was received',
  workInProgress: 'Work in progress',
};

export default msgs;
