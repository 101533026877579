const labels = {
  address: 'Address',
  amount: 'Amount',
  balance: 'Balance',
  connectWallet: 'Connect Wallet',
  daoAddress: 'DAO Address',
  duration: 'Duration',
  execute: 'Execute',
  feeRate: 'Fee rate',
  founder: 'Founder',
  gauge: 'Gauge',
  manage: 'Manage',
  manageAdjustments: 'Manage Adjustments',
  manageInvestments: 'Manage Investments',
  manageLiquidity: 'Manage liquidity',
  memo: 'Memo',
  mintTokens: 'Mint Tokens',
  more: 'More',
  receiver: 'Receiver',
  referencePrice: 'Reference Price',
  reward: 'Reward',
  search: 'Search',
  select: 'Select',
  token: 'Token',
};

export default labels;
