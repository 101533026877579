const BECH32_PREFIX = 'osmo';

const PREFIXES = Object.freeze({
  ACCOUNT: {
    ADDRESS: `${BECH32_PREFIX}`,
    KEY: `${BECH32_PREFIX}pub`,
  },
  VALIDATOR: {
    CONSENSUS: {
      ADDRESS: `${BECH32_PREFIX}valcons`,
      KEY: `${BECH32_PREFIX}valconspub`,
    },
    OPERATOR: {
      ADDRESS: `${BECH32_PREFIX}valoper`,
      KEY: `${BECH32_PREFIX}valoperpub`,
    },
  },
});

const TOKEN = Object.freeze({
  NAME: 'osmo',
  SYMBOL: 'OSMO',
  DENOM: 'uosmo',
  EXPONENT: 6,
  STAKEABLE: true,
});

const GAS = Object.freeze({
  LIMIT: 250000,
  PRICE: {
    AVERAGE: 0.025,
    HIGH: 0.04,
    LOW: 0.0025,
  },
});

const INFO = Object.freeze({
  rpc: process.env.VUE_APP_OSMOSIS_RPC,
  rest: process.env.VUE_APP_OSMOSIS_LCD,
  chainId: process.env.VUE_APP_OSMOSIS_ID,
  chainName: process.env.VUE_APP_OSMOSIS_NAME,
  stakeCurrency: {
    coinDenom: TOKEN.SYMBOL,
    coinMinimalDenom: TOKEN.DENOM,
    coinDecimals: TOKEN.EXPONENT,
  },
  bip44: {
    coinType: 118,
  },
  bech32Config: {
    bech32PrefixAccAddr: PREFIXES.ACCOUNT.ADDRESS,
    bech32PrefixAccPub: PREFIXES.ACCOUNT.KEY,
    bech32PrefixValAddr: PREFIXES.VALIDATOR.OPERATOR.ADDRESS,
    bech32PrefixValPub: PREFIXES.VALIDATOR.OPERATOR.KEY,
    bech32PrefixConsAddr: PREFIXES.VALIDATOR.CONSENSUS.ADDRESS,
    bech32PrefixConsPub: PREFIXES.VALIDATOR.CONSENSUS.KEY,
  },
  currencies: [
    {
      coinDenom: TOKEN.SYMBOL,
      coinMinimalDenom: TOKEN.DENOM,
      coinDecimals: TOKEN.EXPONENT,
    },
  ],
  feeCurrencies: [
    {
      coinDenom: TOKEN.SYMBOL,
      coinMinimalDenom: TOKEN.DENOM,
      coinDecimals: TOKEN.EXPONENT,
      gasPriceStep: {
        low: GAS.PRICE.LOW,
        average: GAS.PRICE.AVERAGE,
        high: GAS.PRICE.HIGH,
      },
    },
  ],
});

const LOCKABLE_DURATIONS = JSON.parse(
  process.env.VUE_APP_OSMOSIS_LOCKABLE_DURATIONS,
);

const LOCK_FILTER = Object.freeze({
  END_TIME: '0001-01-01T00:00:00Z',
});

const POOL_ID = process.env.VUE_APP_OSMOSIS_POOL_ID;

const POOL_TOKEN = Object.freeze({
  NAME: `gamm/pool/${POOL_ID}`,
  EXPONENT: 18,
});

const WALLET = process.env.VUE_APP_OSMOSIS_WALLET;

export default Object.freeze({
  BECH32_PREFIX,
  GAS,
  INFO,
  LOCKABLE_DURATIONS,
  LOCK_FILTER,
  POOL_ID,
  POOL_TOKEN,
  PREFIXES,
  TOKEN,
  WALLET,
});
