import { OSMOSIS } from '@/constants';
import { msgBuilder } from '@/utils';
import { FEES } from 'osmojs';

export default {
  async bondPoolToken(
    { commit, dispatch, rootGetters },
    { amount, duration, translator, context },
  ) {
    const owner = rootGetters['keplr/wallet'];
    const denom = `gamm/pool/${OSMOSIS.POOL_ID}`;
    const msg = msgBuilder.buildMsgLockTokens({
      coins: [{ denom, amount }],
      duration,
      owner,
    });
    const chainInfo = OSMOSIS.INFO;
    const fee = FEES.osmosis.swapExactAmountIn('medium');
    commit('setExecuting', true);
    const client = await dispatch(
      'keplr/getOsmosisClient',
      { chainInfo },
      { root: true },
    );
    const res = await dispatch(
      'keplr/signAndBroadcastTransaction',
      { msgs: [msg], fee, client, translator, context },
      { root: true },
    );
    await dispatch('poolManagement/refreshPoolManagement', null, {
      root: true,
    });
    commit('setExecuting', false);
    return res;
  },
};
