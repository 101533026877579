const ID = Object.freeze({
  CASH_EQUIVALENTS: 'cash_equivalents',
  SALE: 'sale',
  VAULT: 'vault',
});

const LABEL = Object.freeze({
  [ID.CASH_EQUIVALENTS]: 'Cash Equivalents',
  [ID.SALE]: 'Sale',
  [ID.VAULT]: 'Vault',
});

const RANK = Object.freeze({
  [ID.SALE]: 1,
  [ID.CASH_EQUIVALENTS]: 2,
  [ID.VAULT]: 3,
});

export default Object.freeze({
  BALANCE_KEY: 'accounts_balance',
  ID,
  LABEL,
  RANK,
});
