import { ROUTES } from '@/constants';
import { loadView } from '../index';

const ManageAdjustmentsView = loadView(
  'manage-adjustments/ManageAdjustmentsView',
);

const manageAdjustmentsRoute = {
  path: ROUTES.PATH.MANAGE_ADJUSTMENTS,
  name: ROUTES.NAME.MANAGE_ADJUSTMENTS,
  component: ManageAdjustmentsView,
  meta: {
    title: ROUTES.TITLE.MANAGE_ADJUSTMENTS,
  },
};

export default manageAdjustmentsRoute;
