import { ROUTES } from '@/constants';
import { loadView } from '../index';

const PoolManagementView = loadView('pool-management/PoolManagementView');

const poolManagementRoute = {
  path: ROUTES.PATH.POOL_MANAGEMENT,
  name: ROUTES.NAME.POOL_MANAGEMENT,
  component: PoolManagementView,
  meta: {
    title: ROUTES.TITLE.POOL_MANAGEMENT,
  },
};

export default poolManagementRoute;
