import { channelsApi, cosmwasmApi } from '@/apis';
import { CONFIG } from '@/constants';
import { cw20sBuilder, stringHandler } from '@/utils';

export default {
  async initApp({ commit, dispatch }) {
    commit('reset');
    commit('setLoading', true);
    const requests = [dispatch('fetchChannels'), dispatch('fetchTokenInfo')];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  async fetchTokenInfo({ commit }) {
    const address = CONFIG.CONTRACT.CW20;
    const query = stringHandler.encodeToBase64('{"token_info":{}}');
    try {
      const response = await cosmwasmApi.requestContractSmartQuery({
        address,
        query_data: query,
      });
      commit('setTokenInfo', response.data.data);
    } catch (error) {
      commit('setError', error);
    }
  },
  async fetchChannels({ commit, getters }) {
    let nextKey = '';
    do {
      try {
        const response = await channelsApi.requestChannels({ nextKey });
        commit('addChannels', response.data.channels);
        nextKey = response.data.pagination.next_key;
      } catch (error) {
        commit('setError', error);
      }
    } while (nextKey);
    const address = CONFIG.CONTRACT.CW20;
    const cw20s = cw20sBuilder.get(address, getters['channels']);
    commit('setCW20s', cw20s);
  },
};
