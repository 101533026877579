import { cosmwasmApi } from '@/apis';
import { CHAIN, CONFIG, CONTRACT } from '@/constants';
import { msgBuilder, stringHandler } from '@/utils';

export default {
  async initSettingsManager(
    { commit, dispatch },
    { chainInfo, translator, context },
  ) {
    commit('reset');
    commit('setLoading', true);
    const requests = [
      dispatch(
        'keplr/suggestChain',
        { chainInfo, translator, context },
        { root: true },
      ),
      dispatch('fetchFounder'),
    ];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  async fetchFounder({ commit }) {
    try {
      const response = await cosmwasmApi.requestContractState(
        CONFIG.CONTRACT.DAO,
      );
      const models = response.data.models;
      for (const model of models) {
        const key = stringHandler.decodeFromHex(model.key);
        if (key === CONTRACT.STATE_KEY.CONFIG) {
          const detail = JSON.parse(
            stringHandler.decodeFromBase64(model.value),
          );
          commit('setDetail', detail);
        }
      }
    } catch (error) {
      commit('setError', error);
    }
  },
  async execute(
    { commit, dispatch, rootGetters },
    { msg, translator, context },
  ) {
    const chainInfo = CHAIN.INFO;
    if (!rootGetters['keplr/isConnected']) {
      await dispatch(
        'keplr/suggestChain',
        { chainInfo, translator, context },
        { root: true },
      );
    }
    const sender = rootGetters['keplr/wallet'];
    const message = msgBuilder.buildMsgExecuteContract({
      sender,
      contract: CONFIG.CONTRACT.DAO,
      msg,
    });
    const fee = msgBuilder.getFee({ chainInfo, limit: CHAIN.GAS.LIMIT });
    commit('setExecuting', true);
    const client = await dispatch(
      'keplr/getChainClient',
      { chainInfo },
      { root: true },
    );
    const res = await dispatch(
      'keplr/signAndBroadcastTransaction',
      { msgs: [message], fee, client, translator, context },
      {
        root: true,
      },
    );
    commit('setExecuting', false);
    return res;
  },
};
