import { initState } from './index';

export default {
  reset(state) {
    Object.assign(state, initState());
  },
  setError(state, error) {
    state.error = error;
  },
  setLoading(state, payload) {
    state.isLoading = payload;
  },
  setDetail(state, payload) {
    state.detail = payload;
  },
  addDetail(state, payload) {
    const detail = Object.assign({}, state.detail, payload);
    state.detail = detail;
  },
};
