import { OSMOSIS } from '@/constants';
import { GasPrice, calculateFee } from '@cosmjs/stargate';
import { MsgExecuteContract } from 'cosmjs-types/cosmwasm/wasm/v1/tx';
import { google } from 'osmojs';
import {
  MsgAddToGauge,
  MsgCreateGauge,
} from 'osmojs/dist/codegen/osmosis/incentives/tx';
import {
  MsgBeginUnlocking,
  MsgLockTokens,
} from 'osmojs/dist/codegen/osmosis/lockup/tx';

const msgBuilder = {
  buildMsgAddToGauge({ owner, gaugeId, rewards }) {
    const message = {
      typeUrl: '/osmosis.incentives.MsgAddToGauge',
      value: MsgAddToGauge.fromPartial({
        owner,
        gaugeId,
        rewards,
      }),
    };
    return message;
  },
  buildMsgBeginUnlocking({ owner, ID, coins }) {
    const message = {
      typeUrl: '/osmosis.lockup.MsgBeginUnlocking',
      value: MsgBeginUnlocking.fromPartial({
        owner,
        ID,
        coins,
      }),
    };
    return message;
  },
  buildMsgCreateGauge({ coins, duration, owner }) {
    const poolId = OSMOSIS.POOL_ID;
    const distributeTo = {
      lock_query_type: 'ByDuration',
      denom: `gamm/pool/${poolId}`,
      duration: buildDurationInSeconds(duration),
      timestamp: new Date('1970-01-01T00:00:00Z'),
    };
    // Date in ISO 8601 without milliseconds and with Z
    // to avoid osmojs date parsing error
    // https://github.com/osmosis-labs/osmojs/issues/68
    const now = new Date().toISOString().split('.')[0] + 'Z';
    const message = {
      typeUrl: '/osmosis.incentives.MsgCreateGauge',
      value: MsgCreateGauge.fromPartial({
        isPerpetual: true,
        owner,
        distributeTo,
        coins,
        startTime: new Date(now),
        numEpochsPaidOver: '1',
      }),
    };
    return message;
  },
  buildMsgExecuteContract({ sender, contract, msg, funds = [] } = {}) {
    const message = {
      typeUrl: '/cosmwasm.wasm.v1.MsgExecuteContract',
      value: MsgExecuteContract.fromPartial({
        sender,
        contract,
        msg: new TextEncoder().encode(msg),
        funds,
      }),
    };
    return message;
  },
  buildMsgLockTokens({ coins, duration, owner }) {
    const message = {
      typeUrl: '/osmosis.lockup.MsgLockTokens',
      value: MsgLockTokens.fromPartial({
        owner,
        duration: buildDurationInSeconds(duration),
        coins,
      }),
    };
    return message;
  },
  getFee({ chainInfo, limit, msgsCount = 1 }) {
    const feeCurrencie = chainInfo.feeCurrencies[0];
    const gasPrice = GasPrice.fromString(
      `${feeCurrencie.gasPriceStep.average * msgsCount}${
        feeCurrencie.coinMinimalDenom
      }`,
    );
    return calculateFee(limit * msgsCount, gasPrice);
  },
};

export default msgBuilder;

const buildDurationInSeconds = (duration) =>
  google.protobuf.Duration.fromPartial({
    seconds: duration,
    nanos: 0,
  });
