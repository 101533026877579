import { cosmwasmApi } from '@/apis';
import { ACCOUNTS, CHAIN, CONFIG, WALLETS } from '@/constants';
import { msgBuilder, stringHandler } from '@/utils';

export default {
  async initManageAdjustments(
    { commit, dispatch },
    { chainInfo, translator, context },
  ) {
    commit('reset');
    commit('setLoading', true);
    const requests = [
      dispatch(
        'keplr/suggestChain',
        { chainInfo, translator, context },
        { root: true },
      ),
      dispatch('fetchAccountsBalance'),
      dispatch('fetchWalletsBalance'),
    ];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  async refreshManageAdjustments({ commit, dispatch }) {
    commit('setBalances', {});
    const requests = [
      dispatch('fetchAccountsBalance'),
      dispatch('fetchWalletsBalance'),
    ];
    await Promise.all(requests);
  },
  async fetchAccountsBalance({ commit }) {
    const query = `{"${ACCOUNTS.BALANCE_KEY}":{}}`;
    const query_data = stringHandler.encodeToBase64(query);
    try {
      const response = await cosmwasmApi.requestContractSmartQuery({
        address: CONFIG.CONTRACT.DAO,
        query_data,
      });
      commit('addBalance', response.data.data);
    } catch (error) {
      commit('setError', error);
    }
  },
  async fetchWalletsBalance({ commit }) {
    const query = `{"${WALLETS.BALANCE_KEY}":{}}`;
    const query_data = stringHandler.encodeToBase64(query);
    try {
      const response = await cosmwasmApi.requestContractSmartQuery({
        address: CONFIG.CONTRACT.DAO,
        query_data,
      });
      commit('addBalance', response.data.data);
    } catch (error) {
      commit('setError', error);
    }
  },
  async execute(
    { commit, dispatch, rootGetters },
    { msg, translator, context },
  ) {
    const chainInfo = CHAIN.INFO;
    if (!rootGetters['keplr/isConnected']) {
      await dispatch(
        'keplr/suggestChain',
        { chainInfo, translator, context },
        { root: true },
      );
    }
    const sender = rootGetters['keplr/wallet'];
    const message = msgBuilder.buildMsgExecuteContract({
      sender,
      contract: CONFIG.CONTRACT.DAO,
      msg,
    });
    const fee = msgBuilder.getFee({ chainInfo, limit: CHAIN.GAS.LIMIT });
    commit('setExecuting', true);
    const client = await dispatch(
      'keplr/getChainClient',
      { chainInfo },
      { root: true },
    );
    const res = await dispatch(
      'keplr/signAndBroadcastTransaction',
      { msgs: [message], fee, client, translator, context },
      {
        root: true,
      },
    );
    await dispatch('refreshManageAdjustments');
    commit('setExecuting', false);
    return res;
  },
};
