<template>
  <div>
    <v-chip :color="isConnected ? 'primary' : 'lime darken-1'">
      <span
        class="font-weight-bold"
        :style="isSmallView ? { 'font-size': '0.75rem' } : {}"
        v-text="isConnected ? address : $t('msgs.noWalletConnected')"
      />
    </v-chip>
    <v-dialog v-model="dialog" max-width="800">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>
            <span
              class="primary--text font-weight-bold"
              v-text="$t('titles.info')"
            />
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon color="grey" dark @click="closeDialog">
              <v-icon>{{ mdiClose }}</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-alert border="left" color="warning" colored-border>
            <div v-html="dialogMessage" />
          </v-alert>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mdiClose } from '@mdi/js';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TheConnectWalletIndicator',
  data() {
    return {
      mdiClose,
      dialog: false,
    };
  },
  computed: {
    ...mapGetters('keplr', ['error', 'isConnected', 'hasKeplr', 'accounts']),
    address() {
      if (!this.accounts.length) {
        return '';
      }
      const address = this.accounts[0].address;
      return `${address.slice(0, 14)}...${address.slice(
        address.length - 3,
        address.length,
      )}`;
    },
    breakpointName() {
      return this.$vuetify.breakpoint.name;
    },
    dialogMessage() {
      const link = `<a href="https://www.keplr.app/">www.keplr.app</a>`;
      return this.hasKeplr ? this.error : `${this.error}. ${link}`;
    },
    isSmallView() {
      return this.breakpointName === 'xs' || this.breakpointName === 'sm';
    },
  },
  watch: {
    error(value) {
      if (value) {
        this.dialog = true;
      }
    },
  },
  methods: {
    ...mapActions('keplr', ['resetError']),
    closeDialog() {
      this.resetError();
      this.dialog = false;
    },
  },
};
</script>
