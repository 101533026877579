export default Object.freeze({
  PATH: {
    HOME: '/home',
    MANAGE_ADJUSTMENTS: '/manage-adjustments',
    MANAGE_INVESTMENTS: '/manage-investments',
    MANAGE_LIQUIDITY: '/manage-liquidity',
    MINT_TOKENS: '/mint',
    NOT_FOUND: '/:notFound(.*)',
    POOL_MANAGEMENT: '/pool-management',
    ROOT: '/',
    SETTINGS: '/settings',
    SETTINGS_MANAGER: 'manager',
    SETTINGS_SUMMARY: 'summary',
  },
  NAME: {
    HOME: 'home',
    MANAGE_ADJUSTMENTS: 'manage-adjustments',
    MANAGE_INVESTMENTS: 'manage-investments',
    MANAGE_LIQUIDITY: 'manage-liquidity',
    MINT_TOKENS: 'mint',
    POOL_MANAGEMENT: 'pool-management',
    SETTINGS: 'settings',
    SETTINGS_MANAGER: 'settings-manager',
    SETTINGS_SUMMARY: 'settings-summary',
  },
  TITLE: {
    HOME: 'Home',
    MANAGE_ADJUSTMENTS: 'Manage Adjustments',
    MANAGE_INVESTMENTS: 'Manage Investments',
    MANAGE_LIQUIDITY: 'Manage Liquidity',
    MINT_TOKENS: 'Mint Tokens',
    POOL_MANAGEMENT: 'Pool Management',
    SETTINGS: 'Settings',
    SETTINGS_MANAGER: 'Settings Manager',
    SETTINGS_SUMMARY: 'Settings Summary',
  },
});
