import { WALLETS } from '@/constants';

export default {
  error: (state) => state.error,
  isLoading: (state) => state.isLoading,
  pool: (state) => state.pool,
  wallets: (state) => {
    const wallets = state.wallets
      .filter((wallet) => wallet.id !== WALLETS.ID.REWARDS)
      .map((wallet) => ({
        id: wallet.id,
        rank: WALLETS.RANK[wallet.id],
        label: WALLETS.LABEL[wallet.id],
        balance: wallet.amount,
      }));
    const assets =
      state.pool && state.pool.pool_assets ? state.pool.pool_assets : [];
    if (assets.length > 0) {
      wallets.push({
        id: WALLETS.ID.RIV_DEX_LIQUIDITY,
        rank: WALLETS.RANK[WALLETS.ID.RIV_DEX_LIQUIDITY],
        label: WALLETS.LABEL[WALLETS.ID.RIV_DEX_LIQUIDITY],
        balance: assets[0].token.amount,
      });
      wallets.push({
        id: WALLETS.ID.USDC_DEX_LIQUIDITY,
        rank: WALLETS.RANK[WALLETS.ID.USDC_DEX_LIQUIDITY],
        label: WALLETS.LABEL[WALLETS.ID.USDC_DEX_LIQUIDITY],
        balance: assets[1].token.amount,
      });
    }
    return wallets.sort((a, b) => a.rank - b.rank);
  },
};
