import { TRANSACTIONS } from '@/constants';

export default {
  error: (state) => state.error,
  isLoading: (state) => state.isLoading,
  isIncreasing: (state) => state.isIncreasing,
  mergedOsmosisHistory: (state) => {
    const historyReceived = filterArrayOfObjects(
      state.osmosis_received.list,
      'key',
      TRANSACTIONS.OSMOSIS_FILTER,
    );
    const historySent = filterArrayOfObjects(
      state.osmosis_sent.list,
      'key',
      TRANSACTIONS.OSMOSIS_FILTER,
    );
    const history = [...historyReceived, ...historySent];
    return history.filter(
      (value, index, self) =>
        index === self.findIndex((el) => el.hash === value.hash),
    );
  },
  osmosisReceived: (state) => ({
    hasMore:
      state.osmosis_received.pagination &&
      state.osmosis_received.pagination.total
        ? state.osmosis_received.list.length <
          state.osmosis_received.pagination.total
        : false,
    history: state.osmosis_received.list,
    offset: state.osmosis_received.offset,
    total: state.osmosis_received.pagination?.total,
  }),
  osmosisSent: (state) => ({
    hasMore:
      state.osmosis_sent.pagination && state.osmosis_sent.pagination.total
        ? state.osmosis_sent.list.length < state.osmosis_sent.pagination.total
        : false,
    history: state.osmosis_sent.list,
    offset: state.osmosis_sent.offset,
    total: state.osmosis_sent.pagination?.total,
  }),
  riv: (state) => ({
    hasMore:
      state.riv.pagination && state.riv.pagination.total
        ? state.riv.list.length < state.riv.pagination.total
        : false,
    history: state.riv.list,
    offset: state.riv.offset,
    total: state.riv.pagination?.total,
  }),
};

function filterArrayOfObjects(arr, filterProp, filterValues) {
  return arr.filter((obj) => filterValues.includes(obj[filterProp]));
}
