<template>
  <v-sheet>
    <the-drawer-routes />
    <v-divider class="mx-3" />
    <div class="text-center text-caption" v-text="version" />
  </v-sheet>
</template>

<script>
import TheDrawerRoutes from './drawer/TheDrawerRoutes.vue';

export default {
  name: 'TheDrawer',
  components: { TheDrawerRoutes },
  computed: {
    version() {
      return `v${process.env.APP_VERSION}`;
    },
  },
};
</script>
