import { cosmwasmApi } from '@/apis';
import { CONFIG } from '@/constants';
import { stringHandler } from '@/utils';

export default {
  async initHomeAccounts({ commit, dispatch }) {
    commit('reset');
    commit('setLoading', true);
    const requests = [dispatch('fetchAccounts')];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  async fetchAccounts({ commit }) {
    const query = '{"accounts_balance":{}}';
    const query_data = stringHandler.encodeToBase64(query);
    try {
      const response = await cosmwasmApi.requestContractSmartQuery({
        address: CONFIG.CONTRACT.DAO,
        query_data,
      });
      commit('setAccounts', response.data.data.accounts_balance);
    } catch (error) {
      commit('setError', error);
    }
  },
};
