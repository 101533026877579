import { cosmwasmApi, osmosisGammApi } from '@/apis';
import { CONFIG, CONTRACT, OSMOSIS } from '@/constants';
import { stringHandler } from '@/utils';

export default {
  async initHomeRivcoin({ commit, dispatch }) {
    commit('reset');
    commit('setLoading', true);
    const requests = [
      dispatch('fetchAccounts'),
      dispatch('fetchDexPrice'),
      dispatch('fetchFounder'),
      dispatch('fetchTokenInfo'),
      dispatch('fetchWalletsBalance'),
    ];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  async fetchDexPrice({ commit, rootGetters }) {
    const cw20s = rootGetters['cw20s'];
    const cw20_1 = cw20s.find((item) => item.id === 1);
    const cw20_2 = cw20s.find((item) => item.id === 2);
    try {
      const response = await osmosisGammApi.requestDexPrice({
        poolId: OSMOSIS.POOL_ID,
        denom1: cw20_1.denom,
        denom2: cw20_2.denom,
      });
      commit('setSpotPrice', response.data.spot_price);
    } catch (error) {
      commit('setError', error);
    }
  },
  async fetchFounder({ commit }) {
    try {
      const response = await cosmwasmApi.requestContractState(
        CONFIG.CONTRACT.DAO,
      );
      const models = response.data.models;
      for (const model of models) {
        const key = stringHandler.decodeFromHex(model.key);
        if (key === CONTRACT.STATE_KEY.CONFIG) {
          const founder = JSON.parse(
            stringHandler.decodeFromBase64(model.value),
          );
          commit('setFounder', founder);
        }
      }
    } catch (error) {
      commit('setError', error);
    }
  },
  async fetchAccounts({ dispatch }) {
    const query = '{"accounts_balance":{}}';
    await dispatch('getContractDetail', {
      address: CONFIG.CONTRACT.DAO,
      queryData: query,
      path: 'accounts',
    });
  },
  async fetchTokenInfo({ dispatch }) {
    const query = '{"token_info":{}}';
    await dispatch('getContractDetail', {
      address: CONFIG.CONTRACT.CW20,
      queryData: query,
      path: 'info',
    });
  },
  async fetchWalletsBalance({ dispatch }) {
    const query = '{"wallets_balance":{}}';
    await dispatch('getContractDetail', {
      address: CONFIG.CONTRACT.DAO,
      queryData: query,
      path: 'wallets',
    });
  },
  async getContractDetail({ commit }, { address, queryData, path }) {
    const query = stringHandler.encodeToBase64(queryData);
    try {
      const response = await cosmwasmApi.requestContractSmartQuery({
        address,
        query_data: query,
      });
      commit('setContractProp', {
        path,
        value: response.data.data,
      });
    } catch (error) {
      commit('setError', error);
    }
  },
};
