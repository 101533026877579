import { cosmwasmApi, osmosisGammApi } from '@/apis';
import { CONFIG, OSMOSIS } from '@/constants';
import { stringHandler } from '@/utils';

export default {
  async initHomeWallets({ commit, dispatch }) {
    commit('reset');
    commit('setLoading', true);
    const requests = [dispatch('fetchWallets'), dispatch('fetchPool')];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  async fetchWallets({ commit }) {
    const query = '{"wallets_balance":{}}';
    const query_data = stringHandler.encodeToBase64(query);
    try {
      const response = await cosmwasmApi.requestContractSmartQuery({
        address: CONFIG.CONTRACT.DAO,
        query_data,
      });
      commit('setWallets', response.data.data.wallets_balance);
    } catch (error) {
      commit('setError', error);
    }
  },
  async fetchPool({ commit }) {
    const poolId = OSMOSIS.POOL_ID;
    try {
      const response = await osmosisGammApi.requestPool(poolId);
      commit('setPool', response.data.pool);
    } catch (error) {
      commit('setError', error);
    }
  },
};
