import { cw20sBuilder } from '@/utils';

export default {
  error: (state) => state.error,
  isLoading: (state) => state.isLoading,
  balances: (state) => state.balances,
  cw20Balances: (state, _getters, _rootState, rootGetters) => {
    const cw20s = rootGetters['cw20s'];
    return cw20sBuilder.mapBalances({ balances: state.balances, cw20s });
  },
};
