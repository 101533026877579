import axios from 'axios';

import { APIS } from '@/constants';

const osmosisIncentivesApi = {
  requestActiveGaugesPerDenom({ denom, nextKey }) {
    const params = {};
    if (denom) {
      params.denom = denom;
    }
    if (nextKey) {
      params['pagination.key'] = nextKey;
    }
    return axios({
      url: `/active_gauges_per_denom`,
      method: 'get',
      baseURL: APIS.OSMOSIS_INCENTIVES,
      params,
    });
  },
  requestGauges({ nextKey }) {
    const params = {};
    if (nextKey) {
      params['pagination.key'] = nextKey;
    }
    return axios({
      url: `/gauges`,
      method: 'get',
      baseURL: APIS.OSMOSIS_INCENTIVES,
      params,
    });
  },
};

export default osmosisIncentivesApi;
