<template>
  <v-list-item-group>
    <base-drawer-menu-item
      :icon="mdiTreasureChest"
      :title="$t('titles.treasury')"
      :to="{ path: ROUTES.PATH.ROOT }"
    />
    <base-drawer-menu-item
      :icon="mdiAccountMultiple"
      :title="$t('titles.users')"
      :to="{ path: ROUTES.PATH.ROOT }"
    />
    <base-drawer-menu-item
      :icon="mdiChartPie"
      :title="$t('titles.stats')"
      :to="{ path: ROUTES.PATH.ROOT }"
    />
    <base-drawer-menu-item
      :icon="mdiChartScatterPlot"
      :title="$t('titles.poolManagement')"
      :to="{ path: ROUTES.PATH.POOL_MANAGEMENT }"
    />
    <base-drawer-menu-item
      :icon="mdiCog"
      :title="$t('titles.settings')"
      :to="{ name: ROUTES.NAME.SETTINGS }"
    />
  </v-list-item-group>
</template>

<script>
import BaseDrawerMenuItem from './BaseDrawerMenuItem.vue';

import { ROUTES } from '@/constants';
import {
  mdiAccountMultiple,
  mdiChartPie,
  mdiChartScatterPlot,
  mdiCog,
  mdiTreasureChest,
} from '@mdi/js';

export default {
  name: 'TheDrawerRoutes',
  components: { BaseDrawerMenuItem },
  data() {
    return {
      ROUTES,
      mdiAccountMultiple,
      mdiChartPie,
      mdiChartScatterPlot,
      mdiCog,
      mdiTreasureChest,
    };
  },
};
</script>
