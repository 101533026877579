<template>
  <v-alert dense outlined type="error">
    <span v-text="$t('msgs.problemOccurred')" />
  </v-alert>
</template>

<script>
export default {
  name: 'TheProblemAlert',
};
</script>
