const LCD = process.env.VUE_APP_CHAIN_LCD;
const OSMOSIS_LCD = process.env.VUE_APP_OSMOSIS_LCD;
const OSMOSIS_PATH = `${OSMOSIS_LCD}/osmosis`;

export default Object.freeze({
  COSWMWASM: `${LCD}/cosmwasm/wasm/v1`,
  IBC_CORE_CHANNEL: `${LCD}/ibc/core/channel/v1`,
  OSMOSIS_GAMM_V1: `${OSMOSIS_PATH}/gamm/v1beta1`,
  OSMOSIS_GAMM_V2: `${OSMOSIS_PATH}/gamm/v2`,
  OSMOSIS_INCENTIVES: `${OSMOSIS_PATH}/incentives/v1beta1`,
  OSMOSIS_LOCKUP: `${OSMOSIS_PATH}/lockup/v1beta1`,
  OSMOSIS_TX: `${OSMOSIS_LCD}/cosmos/tx/v1beta1`,
  TX: `${LCD}/cosmos/tx/v1beta1`,
});
