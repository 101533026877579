const ID = Object.freeze({
  GENESIS: 'genesis',
  REWARDS: 'rewards',
  RIV_DEX_LIQUIDITY: 'riv-dex-liquidity',
  SALE: 'sale',
  USDC_DEX_LIQUIDITY: 'usdc-dex-liquidity',
});

const LABEL = Object.freeze({
  [ID.GENESIS]: 'Genesis',
  [ID.RIV_DEX_LIQUIDITY]: 'Riv DEX Liquidity',
  [ID.SALE]: 'Sale-out',
  [ID.USDC_DEX_LIQUIDITY]: 'USDC DEX Liquidity',
});

const RANK = Object.freeze({
  [ID.GENESIS]: 1,
  [ID.RIV_DEX_LIQUIDITY]: 2,
  [ID.USDC_DEX_LIQUIDITY]: 3,
  [ID.SALE]: 4,
});

export default Object.freeze({
  BALANCE_KEY: 'wallets_balance',
  ID,
  LABEL,
  RANK,
});
