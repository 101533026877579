import { regexpBuilder, stringHandler } from './index.js';

const WASM_PREFIX = 'wasm';

const cw20sBuilder = {
  get(address, channels) {
    let id = 1;

    const cw20s = [{ id, denom: 'uusdc', exponent: 6, label: 'USDC' }];
    id++;

    const rexexp = regexpBuilder.getPrefixRegexp(WASM_PREFIX);
    const channel = channels.find((channel) => rexexp.test(channel.port_id));
    if (!channel) {
      return cw20s;
    }

    const denom = buildCW20Denom(address, channel);
    if (!denom) {
      return cw20s;
    }
    const riv = { id, denom, exponent: 5, label: 'RIV' };
    cw20s.push(riv);
    return cw20s;
  },
  mapBalances({ balances, cw20s }) {
    return balances.reduce((acc, balance) => {
      const index = cw20s.findIndex((cw20) => cw20.denom === balance.denom);
      if (index < 0) {
        return acc;
      }
      acc.push({
        amount: balance.amount,
        denom: balance.denom,
        label: cw20s[index]['label'],
        exponent: cw20s[index]['exponent'],
      });
      return acc;
    }, []);
  },
};

export default cw20sBuilder;

const buildCW20Denom = (address, channel) => {
  try {
    const seed = `${channel.counterparty.port_id}/${channel.counterparty.channel_id}/cw20:${address}`;
    const unhashedIbc = stringHandler.encodeToSha256(seed);
    return 'ibc/' + unhashedIbc.toUpperCase();
  } catch (error) {
    return null;
  }
};
